<script>
export default {
	name: 'Footer',
	data() {
		return {};
	},
	methods: {
		getImage: image => {
			return `${require(`@/assets/images/layouts/FOOTER/${image}`)}`;
		}
	}
};
</script>

<template>
	<div class="footer bottom-0 mx-auto w-full">
		<div
			class="container h-12 flex items-center justify-between m-auto px-5 md:px-4"
		>
			<div v-if="false" class="footer__left">
				<span
					class="bg-yellow inline-block  mr-2 rounded-full w-2 h-2"
				></span>
				<span
					class="bg-green inline-block  mr-2 rounded-full w-2 h-2"
				></span>
				<span
					class="bg-red inline-block  mr-2 rounded-xl w-12 h-2"
				></span>
			</div>
			<div class="footer__center mx-auto">
				<img
					class="inline-block logo-fotter"
					:src="getImage('Logo_GE_2.png')"
					alt="Grupo Elektra"
				/>
			</div>
			<div v-if="false" class="footer__right">
				<span
					class="bg-red inline-block  ml-2 rounded-xl w-12 h-2"
				></span>
				<span
					class="bg-green inline-block  ml-2 rounded-full w-2 h-2"
				></span>
				<span
					class="bg-yellow inline-block  ml-2 rounded-full w-2 h-2"
				></span>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.logo-fotter{
	height: 60px;
}
</style>
