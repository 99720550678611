<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import BaseLoading from "@/components/BaseLoading.vue";
import SecondaryButton from "@/components/SecondaryButton.vue";
import Footer from "@/layouts/Footer.vue";

export default {
	components: {
		BaseLoading,
		SecondaryButton,
		Footer,
	},
	data() {
		return {
			hash: null,
			credentials: null,
			username: null,
			password: null,
		};
	},
	created() {
		this.setLoading(true);
		this.cleanErrors();
		this.hash = this.$route.query.code || null;
		if (this.hash) {
			this.getLogin();
		} else {
			this.setLoading(false);
		}
	},
	computed: {
		...mapGetters("loginStore", ["readError"]),
		...mapGetters({
			isLoading: "loading/isLoading",
			isAdmin: "auth/readIsAdmin",
		}),
	},
	methods: {
		...mapMutations("loginStore", ["SET_HASH","SET_CREDENTIALS","SET_ERROR"]),
		...mapMutations({
			//LOGIN_DATA
			setLoading: "loading/SET_LOADING",
		}),
		...mapActions("loginStore", ["loginActions"]),
		...mapActions({
			getAuth: "auth/getUser",
		}),
		getImage(image) {
			return `${require(`@/assets/images/views${this.$route.path.toUpperCase()}/${image}`)}`;
		},
		getLogin() {
			this.credentials = {
				username: this.username,
				password: this.password,
			};
			this.setLoading(true);
			this.cleanErrors();
			this.SET_CREDENTIALS(this.credentials);
			this.SET_HASH(this.hash);
			this.loginActions("getLogin")
				.then((result) => {
					if (result) {
						this.getAuth().then(() => {
							this.isAdmin
								? this.$router.push({ name: "Metricas" })
								: this.$router.push({ name: "Inicio" });
						});
					}
				})
				.finally(() => {
					if (this.readError) this.setLoading(false);
				});
		},
		cleanErrors() {
			this.SET_ERROR(null);
		},
	},
};
</script>

<template>
	<div class="layout max-w-sm m-auto">
		<div class="logo-wrap flex justify-center items-center py-6 flex-none">
			<img
				class="logo-h"
				src="@/assets/images/layouts/APP/Logo_GE_2.png"
			/>
		</div>
		<BaseLoading v-if="isLoading"></BaseLoading>
		<div v-else class="main h-full">
			<div
				v-if="!hash"
				class="mx-3 items-center bg-gray p-6 pb-10 rounded-xl shadow-sm flex-none"
			>
				<span
					class="block m-auto mb-6 flex-none flex items-center justify-center h-32 w-32 p-6 black bg-white rounded-full"
				>
					<font-awesome-icon class="h-20" icon="fa-solid fa-user"
				/></span>
				<form>
					<label
						class="flex-none block w-full mb-3 text-xl"
						for="username"
						>Usuario</label
					>
					<input
						v-model="username"
						class="w-full h-12 mb-6 px-4 py-2 text-lg bg-white rounded-3xl"
						type="text"
						name="username"
						id="username"
						placeholder="Ingrese su usuario"
						required
					/>
					<label class="block w-full mb-3 text-xl" for="password"
						>Contraseña</label
					>
					<input
						v-model="password"
						class="w-full h-12 mb-10 px-4 py-2 text-lg bg-white rounded-3xl"
						type="password"
						name="password"
						id="password"
						required
						placeholder="Ingrese su contraseña"
					/>
					<span v-if="readError" class="block mb-3 red text-center b">{{
						readError
					}}</span>
					<div class="flex flex-col items-center">
						<secondary-button
							v-if="!isLoading"
							@click.native="getLogin"
							text="Entrar"
						></secondary-button>
					</div>
				</form>
			</div>
			<div v-else-if="readError" class="flex flex-col items-center">
				<img
					class="image"
					:src="getImage('login_img.png')"
					alt="Copa en Podio"
				/>
				<div class="block mb-3 elektra-red text-center b flex-none">
					Estamos preparando tus datos
					<span class="block">por favor da clic en "Actualizar"</span>
				</div>
				<secondary-button
					class="mt-3"
					@click.native="getLogin"
					text="Actualizar"
				></secondary-button>
			</div>
		</div>
		<Footer class="absolute"></Footer>
	</div>
</template>
<style lang="scss" scoped>
.layout {
	height: 100vh;
}

.image {
	height: 40vh;
}
</style>
